<template>
    <MiniTitle title="Q & A" subTitle="よくある質問" />
    <div class="qa-part">
        <hr />
        <div class="qa-block" v-for="(q, key) in questions" :key="key">
            <q-expansion-item>
                <template v-slot:header>
                    <q-item-section avatar>
                        <p class="question-icon">Q</p>
                    </q-item-section>

                    <q-item-section>
                        {{ q.content }}
                    </q-item-section>
                </template>
                <q-card>
                    <q-card-section>
                        <span class="answer-icon">A</span>{{q.answer}}
                    </q-card-section>
                </q-card>
            </q-expansion-item>
            <hr />
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import MiniTitle from '../ui/miniTitle.vue';

export default {
    name: "Hero",
    props: {
        title: String,
        subTitle: String,
    },
    setup() {
        const questions = ref([
            { content: "ホームページの制作期間はどれくらいかかりますか？", answer: "大体、1〜3ヶ月かかります。案件によって前後しますので、ぜひ一度お問い合わせください。" },
            { content: "ホームページの制作料金はどれくらいかかりますか？", answer: "ホームページの規模や内容によって異なりますが、一般的なコーポレートサイトの場合、平均60万円です。案件によって前後しますので、ぜひ一度お問い合わせください。" },
            { content: "ロゴ制作は行なっていますか？", answer: "はい、承っております。お気軽にお問い合せください。" },
            { content: "スマートフォンやタブレットに対応したホームページを制作いただくことはできますか？", answer: "もちろん対応しております。制作費に含まれておりますので、ご安心ください。" },
        ]);
        return {
            questions
        };
    },
    components: { MiniTitle }
};
</script>

<style scoped>
.qa-part{
    margin-bottom:5rem;
}
.question-icon{
    font-size: 1.2rem;
    font-weight: bold;
    color:#4B009F;
    margin-bottom:0;
}
.answer-icon{
    font-size: 1.2rem;
    font-weight: bold;
    margin-left:0.1rem;
    margin-right:2.6rem;
    color: #000;
}
@media (max-width: 960px) {
    
}
</style>