<template>
  <div class="hero">
    <div class="hero_cover">
      <div class="hero-text">
        <h1>CONTACT</h1>
      </div>
      <div class="hero-img">
        <img src="../../assets/contact_hero.svg" />
      </div>
      <div class="contact-text">
        Webサイトやデザインは弊社にお任せください！<br />
        お客様はもちろん、お客様のお客様にも価値のあるプロダクトをお作りします👍
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
  width: 100%;
  height: 500px;
  color: #848484;
}
.hero_cover {
  position: relative;
  height: 100%;
}
.hero-img {
  margin: 8% auto 0;
  width: 40%;
}
.hero-text {
  position: absolute;
  top: -10%;
  left: 30.5%;
  z-index: 100;
}

.hero-text h1 {
  font-size: 3.8rem;
  color: white;
  letter-spacing: 0.3rem;
  font-weight: bold;
}
.contact-text {
  margin-top: 10rem;
  font-size: 1.2rem;
  text-align: center;
  color: #000000;
  font-weight: bold;
}
@media (max-width: 960px) {
  .hero-img {
    width: 80%;
  }

  .hero-text h1 {
    font-size: 3.5rem;

  }

  .hero-text {
    left: 11.5%;
  }

}

@media (max-width: 520px) {
  .hero {
    height:100%;
    margin-bottom:3rem;
  }

  .scroll-down {
    top: 60%;
    left: 21%;
  }

  .hero-text {
    top: -18%;
    left: 11.5%;
  }

  .hero-text h1 {
    font-size: 2.3rem;

  }
  .contact-text {
    margin-top: 7rem;
    font-size: 1rem;
    text-align: center;
    color: #000000;
    font-weight: bold;
  }
}
</style>
