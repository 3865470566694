<template>
    <div class="content-title">
        <h1>{{title}}</h1>
        <p>- {{subTitle}} -</p>
    </div>
</template>
<script>
export default {
    name: "Hero",
    props: {
        title: String,
        subTitle: String,
    },
};
</script>

<style scoped>
.content-title {
  color: #04005d;
}
.content-title h1 {
  font-size: 4rem;
  margin: 0;
  font-weight: bold;
}
.content-title p {
  font-weight: bold;
}
@media (max-width: 960px) {
  .content-title h1 {
    font-size: 2rem;
    line-height: 2;
  }
}
</style>