<template>
  <div class="scroll-down">
    <div>scroll down</div>
    <div class="scroll-border"></div>
  </div>
</template>

<style scoped>
.scroll-down {
  transform: rotate(-90deg);
  color: #4b009f;
  width: 200px;
}
.scroll-border {
  width: 150px;
  height: 2px;
  margin: 0;
  background-color: #4b009f;
}
@media (max-width: 960px) {
.scroll-border {
    width: 200px;
  }
}

@media (max-width: 520px) {
  .scroll-border {
      width: 130px;
    }
}
</style>
