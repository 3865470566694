<template>
  <div class="about">
    <Hero />
    <img src="../assets/mission_image.svg" style="width: 100%;" />
    <MissionPart />
    <VisionPart />
    <LinkButton title="SERVICES" link="/services" />
  </div>
  <FooterContact />
</template>
<script>
import Hero from "../components/About/Hero";
import MissionPart from "../components/About/MissionPart";
import VisionPart from "../components/About/VisionPart";
import FooterContact from "../components/FooterContact";
import LinkButton from "../components/ui/linkButton.vue";

export default {
  name: "About",
  components: {
    Hero,
    MissionPart,
    VisionPart,
    FooterContact,
    LinkButton
},
};
</script>

<style>
</style>