<template>
    <div class="content-title">
        <h1>{{ title }}</h1>
    </div>
</template>
<script>
export default {
    name: "Hero",
    props: {
        title: String,
    },
};
</script>

<style scoped>
.content-title {
    margin:200px 0 100px;
    color: #04005d;
    display: flex;
    align-items: center;
}
.content-title:before,
.content-title:after {
    content: "";
    height: 2px;
    background: #04005D;
    margin: 0 1em;
}
.content-title:before{
    width:10%;
}
.content-title:after {
    flex-grow: 1;
}

.content-title h1 {
    font-size: 4rem;
    margin: 0;
    font-weight: bold;
}

.content-title p {
    font-weight: bold;
}
@media (max-width: 960px) {
    .content-title {
        margin: 50px 0 20px;
    }
    .content-title:before {
        width: 8%;
    }
    .content-title h1 {
        font-size: 1.8rem;
        margin: 0;
        font-weight: bold;
    }
}
</style>