<template>
  <div class="form-box" style="max-width: 800px">
    <div class="form-box_front_text">
      以下の内容で送信してもよろしいでしょうか。<br>ご確認ください。
      <hr />
    </div>
    <q-form class="q-gutter-lg">
      <q-input v-model="form.name" readonly square label="お名前" lazy-rules />
      <q-input
        v-model="form.email"
        readonly
        square
        label="メールアドレス"
        lazy-rules
      />
      <q-input
        v-model="form.phone"
        readonly
        square
        type="tel"
        label="電話番号"
        hint="例：000-0000-0000"
        lazy-rules
      />
      <q-input
        v-model="form.contentType"
        readonly
        square
        label="お問い合わせ種別"
        lazy-rules
      />
      <q-input
        v-model="form.content"
        readonly
        square
        label="お問い合わせ内容"
        type="textarea"
      />
    </q-form>
    <div class="form-btn-box_check">
      <q-btn
        class="form-sub-color-btn"
        size="1rem"
        label="修正する"
        @click="fixMethod"
      />
      <q-btn
        class="form-main-color-btn send_btn"
        size="1rem"
        label="送信する"
        @click="sendMethod"
      />
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import axios from 'axios'
export default {
  name: "FormCheck",
  components: {},
  props: {
    formData: {},
  },
  setup(props, context) {
    const form = reactive(props.formData);
    const fixMethod = () => {
      context.emit("stepperBackMethod");
    };
    const sendMethod = async () => {
      await axios
        .post("./mail.php", form)
        .then((res) => {
          console.log(res)
          context.emit("stepperMethod");
        })
        .catch((err) => {
          console.log(err.response);
          alert("送信に失敗しました。再度送信していただくか、info@noverx.comに直接お問い合せください。");
        });
    };
    return {
      form,
      fixMethod,
      sendMethod,
    };
  },
};
</script>

<style>
.form-btn-box_check {
  text-align: center;
  margin-top: 5rem !important;
}
.send_btn {
  margin-left: 3rem;
}
@media (max-width: 960px) {
  .form-box_front_text {
    font-size: 0.8rem;
    text-align: left;
  }
}
</style>
