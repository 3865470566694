<template>
    <div class="breadcrumbs">
        <p>-  {{ this.$route.matched[0]["meta"]["breadcrumb"] }}</p>
    </div>

</template>

<script>
export default {
    name: "FooterContact",
    methods: {
        contact() {
            this.$router.push("/contact");
        },
    },
};
</script>

<style scoped>
.breadcrumbs{
    background-color: #F0F0F0;
    padding:0.5rem 0;
    padding-left:3rem;
    margin-top:5rem;
}
.breadcrumbs p {
    margin:0;
    font-weight: bold;
}
</style>
