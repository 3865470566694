<template>
    <AboutTitle title="FLOW" />
    <div class="flow-part">
        <div class="flow-card" v-for="(i, key) in flows" :key="key">
            <div class="flow-block_step">
                <span class="flow-block_step_title">step {{ i.step }}</span>
            </div>
            <div class="flow-block_wrap">
                <div class="flow-block_content">
                    <div class="flow-block_content_head">
                        {{ i.head }}
                        <hr />
                    </div>
                    <div class="flow-block_content_text">
                        {{ i.content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import AboutTitle from '../ui/aboutTitle.vue';

export default {
    name: "Hero",
    props: {
        title: String,
        subTitle: String,
    },
    setup() {
        const flows = ref([
            { step: "1", head: "ENTRY", content: "Contactフォームに必要事項をご入力の上、送信してください。採用担当が折り返しご連絡差し上げます。" },
            { step: "2", head: "EXAMINATION", content:"入力していただいた情報から、NOVERXでご一緒に活動できるスキル等を審査いたします。" },
            { step: "3", head: "INTERVIEW", content: "スキル等の審査後、面接のご案内をさせていただきます。回数は、1~2回を想定しております。" },
            { step: "4", head: "AGREEMENT", content: "雇用契約をいたします。雇用形態に関しましては、ご相談ください。" },
        ]);
        return {
            flows
        };
    },
    components: { AboutTitle }
};
</script>

<style scoped>
.flow-part {
    padding: 0 10%;
    display: flex;
    margin-bottom: 200px;
}
.flow-card{
    width: 30%;
}

.flow-block_wrap {
    border: 2px solid #04005D;
    padding: 1rem;
    margin-left: 2rem;
    box-shadow: -0.5rem 0.5rem #04005D;
    height: 300px;
}

.flow-block_step {
    text-align: center;
    padding-left: 2rem;
}

.flow-block_step_title {
    font-size: 1.1rem;
    font-weight: bold;
}


.flow-block_content {
    align-items: center;
    font-size: 1.1rem;
    color: #04005D;
}

.flow-block_content_head {
    text-align: center;
    font-weight: bold;
    margin-bottom:2rem;
}

.flow-block_content_text{
    font-size: 1rem;
    line-height:2;
}
@media (max-width: 960px) {
    .flow-part {
        padding: 0 10%;
        display: block;
        margin-bottom: 200px;
    }

    .flow-card {
        width: 100%;
    }
    .flow-block_step {
        text-align: center;
        padding-left: 0;
    }

    .flow-block_wrap {
        border: 2px solid #04005D;
        padding: 1rem;
        margin-left:0;
        box-shadow: -0.5rem 0.5rem #04005D;
        height: 100%;
        margin-bottom:2rem;
    }
}
</style>