<template>
  <div class="philosophy">
    <div class="mission-part">
      <div class="philosophy_mini-title">Mission</div>
      <div class="philosophy_main-text">
        Fusion of Sensibility<br />
        and Technology
      </div>
      <div class="philosophy_sub-text">
        感性とテクノロジーの融合で<br />
        心豊かな社会の実現に貢献する。
      </div>
    </div>
    <hr />
    <div class="vision-part">
      <div class="philosophy_mini-title">Vision</div>
      <div class="philosophy_main-text">
        Creating the Best <br />
        Experience from Digital
      </div>
      <div class="philosophy_sub-text">
        デジタル領域から最高の体験を創り出す。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Philosophy",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.philosophy {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
.philosophy_mini-title {
  font-size: 2rem;
}
.philosophy_main-text {
  margin-top: 30px;
  font-size: 5rem;
  color: #4b009f;
  font-weight: bold;
}

.philosophy_sub-text {
  margin-top: 50px;
  font-size: 2rem;
  font-weight: bold;
}
.mission-part {
  margin: 200px 0;
}
.vision-part {
  margin: 200px 0 100px;
}
@media (max-width: 960px) {
  .mission-part {
    margin: 100px 0 50px;
    }
  .vision-part {
    margin: 100px 0 50px;
  }
  .philosophy_mini-title {
    font-size: 1.3rem;
  }
  
    .philosophy_main-text {
      margin-top: 30px;
      font-size: 1.8rem;
      color: #4b009f;
      font-weight: bold;
    }
  
    .philosophy_sub-text {
      margin-top: 20px;
      font-size: 1.1rem;
      font-weight: bold;
    }
}
</style>
