<template>
  <breadcrumbs/>
  <div class="footer-contact">
    <div class="footer-contact_border"></div>
    <div class="footer-contact_btn_cover">
      <button v-on:click="contact" class="main-btn contact-btn">
        CONTACT
        <p>お気軽にお問い合わせください！</p>
      </button>
      <button v-on:click="joinus" class="main-btn contact-btn">
        RECRUIT
        <p>お気軽にお問い合わせください！</p>
      </button>
    </div>
  </div>
</template>

<script>
import breadcrumbs from './ui/breadcrumbs.vue';
export default {
  components: { breadcrumbs },
  name: "FooterContact",
  methods: {
    contact() {
      this.$router.push("/contact");
    },
    joinus() {
      this.$router.push("/joinus");
    },
  },
};
</script>

<style scoped>
.footer-contact {
  color: black;
  background-color: #ffffff;
  padding: 0.5rem 0;
  text-align: center;
  margin-bottom: 1rem;
}
.footer-contact_border {
  margin: 3rem auto;
  width: 2px;
  height: 100px;
  background-color: black;
}
.contact-btn {
  color: #4b009f;
  width: 35%;
  height: 200px;
  font-size: 3rem;
  font-weight: bold;
  padding: 1rem 0 0.5rem;
  border-radius: 0.8rem;
  margin:2rem;
}
.contact-btn p {
  font-size: 0.9rem;
}
.contact-btn:hover {
  color: white;
  background-color: #4b009f;
  transform: scale(1.1);
  transition: .6s cubic-bezier(0.33, 1, 0.68, 1);
}
@media (max-width: 960px) {
  .footer-contact_btn_cover{
    display: block;
  }
  .contact-btn {
    color: #4b009f;
    width: 80%;
    height: 120px;
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem 0;
    border-radius: 0.8rem;
    margin: 1.3rem;
  }
}
</style>
