<template>
    <aboutTitle title="MISSION" />
    <div class="mission">
        <h2>感性とテクノロジーの融合で<br class="res-br">心豊かな社会の実現に貢献する</h2>

    </div>
    <div class="mission-text">
        <div class="mission-text_minih">
            <h3>感性とテクノロジーを融合させるということ</h3>

        </div>
        <div class="mission-text_content">
            <p>テクノロジーの進歩するスピードは年々早くなり、今や追いつけないレベルになっています。</p>
            <p>テクノロジーを利用したプロダクトは毎年多くのものが出ていますが、スピードを重視する傾向から、人の感性を置いてけぼりにしたものもその分多くあります。</p>
            <p>そのような状況を見て、本来は人の役に立つべきものであるテクノロジーが人間にとって良いものではなくなりつつあるのではないかと疑問を持ちました。</p>
            <p>テクノロジーと触れていく中で、テクノロジーを利用しながら人間にとっていいプロダクトを作るためには、人の感性を重視したデザインをテクノロジーと融合させることで可能になると気付きました。</p>
            <br>
            <p>NOVERXの使命はテクノロジーと感性の融合によって、本当に価値のあると思えるプロダクトを生み出すことができると証明することです。</p>
        </div>
        <div class="mission-text_minih">
            <h3>NOVERXにとっての感性とは</h3>

        </div>
        <div class="mission-text_content">
            <p>NOVERXにとって感性とは、人それぞれ持っているものであり、大切にすべき個性であり、本質的な価値を表現する存在です。</p>
            <p>プロダクトを使う人々の感性とプロダクトが持ちうる感性を結びつけるものもまた感性であると考えています。</p>
        </div>
        <div class="mission-mini-image">
            <img src="../../assets/mission_mini_image.svg">
        </div>
    </div>
</template>
<script>
import aboutTitle from '../ui/aboutTitle.vue';
export default {
    name: "MissionPart",
    components: {
        aboutTitle
    }
};
</script>
<style scoped>
.mission {
    margin-bottom: 10rem;
}
.mission h2{
    color:#4B009F;
    text-align: center;
    font-weight: bold;
    font-size:2.5rem;
}
.mission-text_minih{
    margin-bottom: 3rem;
}
.mission-text_minih h3{
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
}
.mission-text_content{
    margin: 0 22% 10rem;
    font-weight: bold;
    font-size: 1.1rem;
}
.mission-mini-image{
    text-align: center;
}
.mission-mini-image img {
    width:45%;
}
@media (max-width: 960px) {
    .mission {
        padding:0 2rem;
    }
    .mission h2 {
        font-size: 1.1rem;
        line-height: 2.5;
    }
    .mission-text_minih h3 {
        font-size: 1.1rem;
    }
    .mission-text_content {
        margin: 0 9% 10rem;
        font-size: 0.9rem;
    }
    .mission-text_content p {
        padding-bottom:1rem;
    }
    .mission-mini-image img {
        width: 75%;
    }
}
</style>