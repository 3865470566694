<template>
  <div id="news-content">
    <div class="news-wrapper">
      <div class="news-title">
        <p>NEWS</p>
      </div>
      <hr />
      <div class="news-all">
        <div class="news-all-one" v-for="(post, k) in posts.data" :key="k">
          <div class="news-all_up">
            <div class="news-all_title">{{ post.title.rendered }}</div>
            <div class="news-all_date">{{ ConvertJST(post.date) }}</div>
          </div>
          <div class="news-all_bottom">
            <div class="news-all_content" v-html="post.content.rendered"></div>
          </div>
          <hr />
        </div>
        <div v-if="!posts.data.length">記事が見つかりませんでした。</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { onMounted, reactive } from "@vue/runtime-core";
export default {
  name: "NewsContent",
  setup() {
    let posts = reactive({
      data: {},
    });
    const ConvertJST = (t) => {
      const time = new Date(t);
      time.setHours(time.getHours() + 9);
      return time.toLocaleString().slice(0, -8);
    };
    const getPosts = () => {
      const url = "https://news.noverx.com/news/wp-json/wp/v2/posts";
      axios.get(url).then((response) => {
        console.log(response.data);
        posts.data = response.data;
      });
    };
    onMounted(() => {
      getPosts();
    });
    return {
      ConvertJST,
      posts,
      getPosts,
    };
  },
};
</script>

<style scoped>
#news-content {
  min-height: 700px;
  margin-bottom: 3rem;
}
.news-wrapper {
  margin: 0 10%;
}
.news-title {
  font-size: 2rem;
  margin-bottom: 0;
}
.news-all {
  margin-top: 3rem;
}
.news-all_up {
  display: flex;
  margin-top: 1rem;
}
.news-all_title {
  font-size: 1.5rem;
}
.news-all_date {
  margin-left: 1rem;
  line-height: 3;
}
@media (max-width: 960px) {
  .news-title {
      font-size: 1.5rem;
      margin-bottom: 0;
    }

  .news-all_title {
    font-size: 1.2rem;
  }
    .news-all_content{
      font-size:0.7rem;
    }
}
</style>