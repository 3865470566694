<template>
    <div id="web-content">
        <div class="content-wrapper">
            <contentTitle title="WEB CREATION" subTitle="WEBサイト制作事業" />
        </div>
        <StrengthPart />
        <div class="do-part">
            <MiniTitle title="WHAT WE CAN DO" subTitle="私たちのできること" />
            <img src="../../assets/What_we_can_do_image.png" />
            <p>※ロゴ制作や、グラフィックデザイン、広告デザインもお受けいたします。</p>
        </div>
        <div class="web-section text-part">
            <div class="content-wrapper">
                <div class="web-content">
                    <h2>おもわず「ファン」になっちゃうようなWEBサイト作りませんか？</h2>

                </div>
                <div class="web-content-text_box">
                    <div class="web-content-text">
                        <p>ファンとは、なんだと思いますか。</p>
                        <p>スポーツチームやアイドルなどに対し、熱心に応援する人のことを指すことが多いと思います。</p>
                        <p>私たちは、そんな「ファン」が付くようなサイト制作を心がけています。</p>
                        <p>どんなWEBサイトでも必ず作られた目的があります。その目的は必ずユーザーに何かのアクションを求めます。</p>
                        <p>しかし正直言って、簡単には「ファン」はついてくれないので、<span
                                style="font-weight:bold;">サイトの導線設計と感性の組み合わせが重要になってきます。</span></p>
                        <p>緻密な導線設計から対象となるユーザーの感性の重視したデザインを作ることで「ファン」がつくと私たちは考えております。
                            どちらかが欠けてしまうと実現は難しいです。</p>
                        <p>WEBサイト制作後も、集客パフォーマンスを向上化するために保守運用・分析レポート等行い、日々改善し運用しながら「本当に価値のあるWEBサイト」をデザインします。</p>
                        <p>このようにしてNOVERXは、ユーザー様の目的を実現するためのWEBサイトを制作いたします。</p>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-bottom:10rem;">
            <LinkButton title="CONTACT" link="/contact" />
        </div>
        <FlowPart />
        <div class="content-wrapper">
            <QAPart />
        </div>
        <div style="margin-bottom:10rem;">
            <LinkButton title="CONTACT" link="/contact" />
        </div>

        <div class="content-wrapper" style="margin-bottom:10rem;">
            <div class="web-section">
                <div class="web-content">
                    <h2>最後に</h2>
                </div>
                <div class="web-content-text_box">
                    <div class="web-content-text">
                        <p>WEBサイトは、ユーザーとのコミュニケーションツールです。</p>
                        <p>WEBサイトを訪れたユーザーは、そこから感じ取る印象を“貴社のイメージ”として記憶します。
                            『どう見えるか』だけでなく『どう感じて欲しいか』『どう機能するか』も重要になります。
                        </p>
                        <p>NOVERXは、お客様のお客様にも価値を感じていただくプロダクトを制作いたします。</p>
                        <p>Contactフォームにて、些細なことでもお気軽にお問い合わせくださいませ。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import contentTitle from '../ui/contentTitle.vue';
import FlowPart from './FlowPart.vue';
import StrengthPart from './StrengthPart.vue';
import MiniTitle from '../ui/miniTitle.vue';
import LinkButton from '../ui/linkButton.vue';
import QAPart from './QAPart.vue';
export default {
    name: "servicesContent",
    components: {
    contentTitle,
    FlowPart,
    StrengthPart,
    MiniTitle,
    LinkButton,
    QAPart
}
};
</script>

<style scoped>
.web-content {
    margin: 10rem 0 5rem;
}

.web-content h2 {
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
}



.web-content-text {
    color: #000;
    margin: 0 10% 5rem;
    font-size: 1.1rem;
    line-height: 2;
}

.text-part{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../assets/fan_line.png");
    height:800px;
}

.content-wrapper {
    margin: 0 10%;
}

.content-title {
    margin-bottom: 5rem;
}

.do-part{
    text-align: center;
}
.do-part img{
    width:35%;
    margin-bottom:1rem;
}


@media (max-width: 960px) {
    .web-content {
        margin: 5rem 0 2rem;
    }
    .web-content h2 {
        font-size: 1.3rem;
        line-height:1.8;
    }
    .content-wrapper {
        margin: 0 8%;
    }

    .content-title {
        margin-bottom: 3rem;
    }
    
    .web-content-text {
        color: #000;
        margin: 0 5% 5rem;
        font-size: 0.8rem;
        line-height: 2;
    }
    .do-part img {
        width: 80%;
        margin-bottom: 1rem;
    }
    .do-part p{
        margin:0 11%;
    }
    .text-part {
        height: auto;
    }
}
</style>