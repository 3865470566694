<template>
  <div class="home">
    <Hero />
    <NewsContent />
  </div>
</template>

<script>
import Hero from "../components/News/Hero";
import NewsContent from "../components/News/NewsContent";

export default {
  name: "News",
  components: {
    Hero,
    NewsContent,
  },
  methods: {
    about() {
      this.$router.push("/about");
    },
  },
};
</script>

<style>
.about-btn {
  color: #4b009f;
  width: 15%;
  padding: 1.5rem 3rem;
}
.about-btn:hover {
  color: white !important;
  background-color: #4b009f;
}
.btn-section {
  text-align: center;
  margin-bottom: 100px;
}
</style>