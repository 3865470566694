<template>
    <div class="mini-title">
        <h3>{{ title }}</h3>
        <p>- {{ subTitle }} -</p>
    </div>
</template>
<script>
export default {
    name: "miniTitle",
    props: {
        title: String,
        subTitle: String,
    },
};
</script>

<style scoped>
.mini-title {
    color: #000;
    text-align: center;
    margin-bottom: 3rem;
}

.mini-title h3 {
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
}

.mini-title p {
    font-weight: bold;
}

@media (max-width: 960px) {
    .mini-title h3 {
        font-size: 1.5rem;
        line-height: 2;
    }
}
</style>