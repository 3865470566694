<template>
    <div class="services-list">
        <router-link :to="card.link" class="service-card" v-for="(card,key) in services" :key="key">
            <div class="service-card_img">
                <img :src="card.img" />

            </div>
            <div class="service-card_title">
                <h2>{{card.title}}</h2><span>- {{card.subtitle}} -</span>
            </div>
            <div class="service-card_btn">
                <div class="service-card_btn_text">view</div>
                <div class="service-card_btn_triangle"></div>

            </div>
        </router-link>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';

export default {
    name: "Hero",
    props: {
        title: String,
        subTitle: String,
    },
    setup() {
        const services = ref([
            { title: "WEB CREATION", subtitle: "WEBサイト制作事業", img: require("../../assets/service_web.svg"),link:"/services/web" },
            { title: "DESIGN PARTNER", subtitle: "デザインパートナー事業", img: require("../../assets/service_dp.svg"), link: "/services/dp" },
            { title: "OYASAI", subtitle: "有機野菜プラットフォーム", img: require("../../assets/service_oyasai.svg"), link: "/oyasai" },
    ]);
        return {
            services
        }
    },
};
</script>

<style scoped>
.services-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin:0 auto;
}
.services-list:after{
    content: "";
    width: 35%;
    margin: 2rem;
    color: aliceblue;
    background-color: transparent;
}
.service-card{
    width: 35%;
    margin: 2rem;
    color: black;
    text-decoration: none !important;
}
.service-card:visited{
    color: black;
}
.service-card_title{
    display: flex;
    margin-bottom: 1rem;
}
.service-card_title h2{
    font-size:2rem;
    line-height:1;
    margin:0;
    font-weight: bold;
    
}
.service-card_title span {
    font-size: 0.8rem;
    font-weight: bold;
    padding-left: 1rem;
    white-space: nowrap;

}
.service-card_img img{
    width:100%
}
.service-card_btn{
    display: flex;
}
.service-card_btn_text{
    line-height: 0.5;
    font-size: 1.1rem;
    font-weight: bold;
    color: #4B009F;
}
.service-card_btn_triangle{
    margin-left:0.5rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 12px;
    border-color: transparent transparent transparent #4B009F;
}
@media (max-width: 960px) {
    .service-card {
        width: 80%;
        margin: 2rem;
        color: black;
        text-decoration: none !important;
    }
    .service-card_title h2 {
        font-size: 1.5rem;
        line-height: 1;
        margin: 0;
        font-weight: bold;
    }
    .service-card_title {
        display: block;
    }
    .service-card_title span {
        padding-left:0;
    }
    .services-list:after {
        display: none;
    }
}
</style>