<template>
  <div id="nav-res_cover" v-show="nav_res">
    <div class="btn-item-box">
      <q-btn flat round dense icon="close" size="30px" style="color:white;" @click="showMenu"></q-btn>
    </div>
    <div class="nav-item">
      <router-link to="/" @click="showMenu">TOP</router-link>
    </div>
    <div class="nav-item">
      <router-link to="/about" @click="showMenu">ABOUT</router-link><br />
    </div>
    <div class="nav-item">
      <router-link to="/about/company" @click="showMenu">Company</router-link>
    </div>
    <div class="nav-item">
      <router-link to="/services" @click="showMenu">SERVICES</router-link><br />
    </div>
    <div class="nav-item">
      <router-link to="/services/web" @click="showMenu">Web Creation</router-link><br />
    </div>
    <div class="nav-item">
      <router-link to="/services/dp" @click="showMenu">Design Partner</router-link>
    </div>
    <div class="nav-item">
      <router-link to="/news" @click="showMenu">NEWS</router-link>
    </div>
    <div class="nav-item">
      <router-link to="/joinus" @click="showMenu">JOIN US</router-link>
    </div>
    <div class="nav-item">
      <router-link to="/contact" @click="showMenu">CONTACT</router-link>
    </div>
  </div>
  <header class="header">
    <div class="header_cover">
      <div class="header-logo_box">
        <router-link to="/">
          <img class="header-logo" src="../assets/logo.svg" />
        </router-link>
      </div>
      <div id="nav-res">
        <div class="nav-item">
          <router-link to="/contact" style="line-height:3;color: #000;text-decoration: none;">CONTACT</router-link>
        </div>
        <q-btn flat round dense icon="density_medium" size="20px" @click="showMenu"></q-btn>
      </div>
      <div id="nav">
        <div class="nav-item">
          <router-link to="/">TOP</router-link>
        </div>
        <div class="nav-item" @mouseenter="aboutMouseover" @mouseleave="aboutMouseleave">
          <router-link to="/about">ABOUT</router-link>
          <div class="dropdown-box" v-if="isOpen.about">
            <router-link to="/about/company" class="dropdown-box_item">Company</router-link>
          </div>
        </div>
        <div class="nav-item" @mouseenter="serviceMouseover" @mouseleave="serviceMouseleave">
          <router-link to="/services">SERVICES</router-link>
          <div class="dropdown-box" v-if="isOpen.service">
            <router-link to="/services/web" class="dropdown-box_item">Web Creation</router-link><br />
            <router-link to="/services/dp" class="dropdown-box_item">Design Partner</router-link>
          </div>
        </div>
        <div class="nav-item">
          <router-link to="/news">NEWS</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/joinus">JOIN US</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/contact">CONTACT</router-link>
        </div>
      </div>

    </div>

  </header>

</template>
<script>
import { reactive, ref } from "@vue/reactivity";
export default {
  name: "Header",
  setup() {
    let isOpen = reactive({
      service: false,
      about:false,
    });
    let nav_res = ref(false);

    const showMenu = () => {
      nav_res.value = !nav_res.value;
    }
    const aboutMouseover = () => {
      isOpen.about = true;
    };
    const aboutMouseleave = () => {
      isOpen.about = false;
    };
    const serviceMouseover = () => {
      isOpen.service = true;
    };
    const serviceMouseleave = () => {
      isOpen.service = false;
    };
    return {
      isOpen,
      nav_res,
      showMenu,
      aboutMouseover,
      aboutMouseleave,
      serviceMouseover,
      serviceMouseleave,
    };
  },
};
</script>
<style>
header {
  width: 100%;
  z-index: 10000;
}
#nav-res{
  display: none;
  position: relative;
}
#nav-res_cover{
  position:fixed;
  background-color: #000;
  opacity: 0.8;
    /* Standard compliant browsers */
    -moz-opacity: 0.8;
    /* Firefox and Mozilla browsers */
    -webkit-opacity: 0.8;
    /* WebKit browser e.g. Safari */
    filter: alpha(opacity=80);
    /* For IE8 and earlier */
  z-index: 1000000;
  width:100%;
  height:100%;
}
#nav-res_cover a{
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
}
#nav-res_cover .nav-item{
  text-align: center;
  margin-bottom: 2rem;
}
.header_cover {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0;
}
.header-logo {
  width: 200px;
}
#nav {
  padding: 5px;
  display: flex;
  font-size: 1.1rem;
}
#nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
.nav-item {
  margin: 0 1rem;
  position: relative;
}
.dropdown-box {
  position: absolute;
  top: 2rem;
  left: -1rem;
  background-color: #000;
  opacity: 0.8;
    /* Standard compliant browsers */
    -moz-opacity: 0.8;
    /* Firefox and Mozilla browsers */
    -webkit-opacity: 0.8;
    /* WebKit browser e.g. Safari */
    filter: alpha(opacity=80);
    /* For IE8 and earlier */
  z-index:100000;
  width:400px;
  padding: 0.2rem 1rem;
}
.dropdown-box_item {
  text-align: center;
  line-height: 2;
  white-space: nowrap;
  color:white !important;
}
.cancel-btn{
  width:50px;
  height:50px;
}
.btn-item-box{
  text-align: right;
}
.btn-item-box i {
  size:200px;
}
@media (max-width: 960px) {
  #nav-res{
    display: flex;

  }
  #nav{
    display:none;
  }
  .header-logo {
    width: 140px;
    padding-top:10px;
  }

  .nav-item{
    font-size: 1rem;
  }
}
</style>
