<template>
    <MiniTitle title="FLOW" subTitle="お問合せから納品までの流れ"/>
     <div class="flow-part">
        <div class="flow-block" v-for="(i,key) in flows" :key="key">
            <div class="flow-block_wrap">
                <div class="flow-block_step">
                    <span class="flow-block_step_title">Step {{i.step}}</span>
                </div>
                <div class="flow-block_content">
                    <div class="flow-block_content_right">
                        {{i.head}}
                    </div>
                    <div class="flow-block_content_line"></div>
                    <div class="flow-block_content_left">
                        <div class="flow-block_content_left_list" v-for="(con_i,con_key) in i.contents" :key="con_key">
                            ・{{con_i}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import MiniTitle from '../ui/miniTitle.vue';

export default {
    name: "Hero",
    props: {
        title: String,
        subTitle: String,
    },
    setup() {
        const flows = ref([
            { step: "1", stepName: "contact", head: "お問い合わせ / ご契約", contents: ["簡易ヒアリングの実施", "ご提案資料・お見積書の作成"] },
            { step: "2", stepName: "hearing", head: "ヒアリング / 企画・戦略", contents: ["ヒアリング実施", "WEBサイトの企画・戦略会議"] },
            { step: "3", stepName: "design", head: "デザイン", contents: ["ワイヤーフレームの作成", "ホームページデザインの制作"] },
            { step: "4", stepName: "coding", head: "コーディング", contents: ["コーディングの実施", "テスト / 動作チェック"] },
            { step: "5", stepName: "marketing", head: "納品 / マーケティング", contents: ["アップロード", "WEBマーケティングの実施"] },
        ]);
        return {
            flows
        };
    },
    components: { MiniTitle }
};
</script>

<style scoped>
.flow-part{
    padding:0 20%;
    margin-bottom:10rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../assets/flow_back.png");
}
.flow-block_wrap{
    border:1px solid #4B009F;
    padding: 0.5rem 2rem 0;
    margin-bottom:1px;
}
.flow-block_step{
    color:#4B009F;

}
.flow-block_step_title {
    font-size: 1.1rem;
    font-weight: bold;
}
.flow-block_step_border {
    padding:0 1rem;
}
.flow-block_content{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding:0 10%;
    font-size: 1.1rem;
    color:#000;
}
.flow-block_content_right{
    width:40%;
    text-align: left;
    font-weight: bold;
}
.flow-block_content_left {
    width: 40%;
}
.flow-block_content_line{
    margin: 1rem auto;
    width: 1px;
    height: 50px;
    background-color: #4B009F;
}
.flow-arrow{
    text-align: center;
    margin: 2rem 0;
}
@media (max-width: 960px) {
    .flow-part {
        padding: 0 5%;
    }
    .flow-block_content {
        display: block;
        padding: 0;
    }
    .flow-block_content_line {
        display: none;
    }
    .flow-block_content_right {
        width: 80%;
    }
    .flow-block_content_left {
        width: 100%;
    }
    .flow-block_wrap {
        border: 2px solid #4B009F;
        padding: 1rem;
        margin-bottom: 1rem;
    }
}
</style>