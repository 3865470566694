<template>
  <div class="form-box" style="max-width: 800px">
    <div class="form-box_front_text">
      以下の項目に入力をお願いいたします。お問い合せ内容を確認後、担当者よりご連絡させて頂きます。
      <hr />
    </div>
    <q-form @submit="toCheck">
      <q-input
        v-model="name"
        square
        label="お名前"
        lazy-rules
        :rules="rules.required"
      />
      <q-input
        v-model="email"
        square
        label="メールアドレス"
        lazy-rules
        :rules="rules.email"
      />
      <q-input
        v-model="phone"
        square
        type="tel"
        label="電話番号"
        hint="例：000-0000-0000"
        lazy-rules
        :rules="rules.phone"
      />
      <q-select
        square
        v-model="contentType"
        :options="options"
        label="お問い合わせ種別"
        lazy-rules
        :rules="rules.required"
      />
      <q-input
        v-model="content"
        square
        label="お問い合わせ内容"
        type="textarea"
        :rules="rules.required"
      />

      <q-toggle v-model="accept" label="個人情報の取り扱いについて同意する" />
      <q-banner
        dense
        inline-actions
        class="text-white bg-red"
        v-show="acceptFlag"
      >
        個人情報の取り扱いについて同意いただいた上で送信いただくようお願いいたします。
      </q-banner>

      <div class="form-btn-box">
        <q-btn
          class="form-main-color-btn"
          type="submit"
          size="1.2rem"
          label="同意の上、入力内容を確認"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import { ref, reactive } from "vue";

export default {
  name: "FormContent",
  props: {
    formData: {
      type: Object,
      default: () => ({
        name: "",
        email: "",
        phone: "",
        contentType: "",
        content: "",
      }),
    },
  },
  setup(props, context) {
    let form = reactive(props.formData);
    let name = ref(props.formData.name);
    let email = ref(props.formData.email);
    let phone = ref(props.formData.phone);
    let contentType = ref(props.formData.contentType);
    let content = ref(props.formData.content);
    const accept = ref(false);
    const acceptFlag = ref(false);
    const options = ref([
      "Webサイトの制作についてのご相談",
      "デザインパートナー事業についてのご相談",
      "OYASAIについてのお問い合せ",
      "採用についてのお問い合せ",
      "M&A・業務提携・アライアンスについてのご相談",
      "取材についてのご依頼",
      "その他お問い合わせ",
    ]);
    const rules = reactive({
      required: [(v) => !!v || "必須事項です"],
      email: [
        (v) => !!v || "必須事項です",
        (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "正しい形式で入力してください";
        },
      ],
      phone: [
        (v) => !!v || "必須事項です",
        (v) => {
          const pattern = /^0[-0-9]{11,12}$/;
          return pattern.test(v) || "正しい形式で入力してください";
        },
      ],
    });

    return {
      name,
      email,
      phone,
      rules,
      contentType,
      options,
      accept,
      acceptFlag,
      content,
      form,

      toCheck() {
        if (accept.value) {
          form = {
            name: name,
            email: email,
            phone: phone,
            contentType: contentType,
            content: content,
          };
          context.emit("formMethod", form);
          context.emit("stepperMethod");
        } else {
          acceptFlag.value = true;
        }
      },
    };
  },
};
</script>

<style>
.form-box {
  margin: 0 auto 200px;
}
.form-btn-box {
  margin-top: 3rem !important;
  text-align: center;
}
.form-box_front_text {
  margin-bottom: 5rem;
  font-size: 0.7rem;
  text-align: right;
}
.q-form {
  width: 100% !important;
}
@media (max-width: 960px) {
  .form-box {
      margin: 0 auto 100px;
    }
  .q-form {
      width: 100% !important;
      font-size:11px;
    }
  .block{
    font-size:0.9rem;
  }
}
</style>
