<template>
  <div id="form">
    <div class="q-pa-lg">
      <q-stepper
        v-model="step"
        flat
        alternative-labels
        animated
        active-icon="none"
        inactive-color="white"
      >
        <q-step :name="1" prefix="01" title="入力" :done="step > 1">
          <FormContent
            @formMethod="getFormData"
            @stepperMethod="stepper"
            :formData="formData.value"
          />
        </q-step>

        <q-step :name="2" title="確認" prefix="02" :done="step > 2">
          <FormCheck
            @stepperMethod="stepper"
            @stepperBackMethod="stepperBack"
            :formData="formData.value"
          />
        </q-step>

        <q-step :name="3" title="完了" prefix="03">
          <div class="sent-text">
            お問い合わせありがとうごさいます。<br />
            送信されました。<br />
            お問い合せ内容を確認後、担当者よりご連絡させて頂きます。
          </div>
        </q-step>
      </q-stepper>
    </div>
  </div>
</template>

<script>
import FormContent from "./Form/FormContent.vue";
import FormCheck from "./Form/FormCheck.vue";
import { useRouter } from "vue-router";
import { reactive, ref } from "vue";
export default {
  name: "Form",
  components: {
    FormContent,
    FormCheck,
  },
  setup() {
    let step = ref(1);
    let formData = reactive([]);
    const router = useRouter();

    const stepper = () => {
      step.value++;
      router.push("#form");
    };
    const stepperBack = () => {
      step.value--;
      router.push("#form");
    };
    const getFormData = (val) => {
      formData.value = val;
    };
    return {
      step,
      stepper,
      stepperBack,
      getFormData,
      formData,
    };
  },
};
</script>

<style>
#form {
  min-height: 600px;
}
.q-stepper__dot {
  border-radius: 0 !important;
  width: 6rem !important;
  height: 4rem !important;
  border: 1px solid gray !important;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  /* background-color: #4b009f !important; */
}
.q-stepper__header {
  width: 70% !important;
  margin: 0 auto !important;
}

.q-stepper__tab {
  color: gray !important;
}
.q-stepper__tab span {
  color: gray !important;
}
.q-stepper__tab--active {
  color: #4b009f !important;
}
.q-stepper__tab--active span {
  color: #fff !important;
}
.form-main-color-btn {
  color: white !important;
  background-color: #4b009f !important;
}
.form-sub-color-btn {
  color: gray !important;
  border: 1px solid gray !important;
}
.sent-text {
  font-size: 1.5rem;
  text-align: center;
}
@media (max-width: 960px) {
    .q-stepper__tab {
      width: 3rem !important;
      height: 2rem !important;
      padding:0 !important;
    }
    .q-stepper__dot {
      width: 3rem !important;
      height: 2rem !important;
      font-size: 1rem !important;
    }
    .q-stepper__title{
      font-size: 0.8rem !important;
    }
}
</style>
