<template>
  <footer>©︎2022 NOVERX Inc. All rights reserved.</footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
  color: #ffffff;
  background-color: #000;
  padding: 0.5rem 0;
  text-align: center;
}
</style>
