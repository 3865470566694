<template>
  <div class="services">
    <Hero />
    <ServicesContent />
  </div>
  <FooterContact />
</template>
<script>
import Hero from "../components/Services/Hero";
import ServicesContent from "../components/Services/ServicesContent";
import FooterContact from "../components/FooterContact";

export default {
  name: "Services",
  components: {
    Hero,
    ServicesContent,
    FooterContact,
  },
  methods: {
    about() {
      this.$router.push("/about");
    },
  },
};
</script>

<style>
</style>