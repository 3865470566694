<template>
    <div class="company">
        <Hero />
        <Info/>
    </div>
    <FooterContact/>
</template>
<script>
import Hero from "../components/Company/Hero";
import Info from "../components/Company/Info.vue";
import FooterContact from "../components/FooterContact";

export default {
    name: "Company",
    components: {
    Hero,
    Info,
    FooterContact
},
};
</script>

<style>
</style>