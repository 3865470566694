<template>
  <div class="dp">
    <Hero/>
    <div class="dp-text">
      Coming soon ...
    </div>
  </div>
  <FooterContact />
</template>
<script>
import FooterContact from "../components/FooterContact";
import Hero from "../components/DP/Hero.vue";

export default {
  name: "DesignPartner",
  components: {
    FooterContact,
    Hero,

},
};
</script>

<style>
  .dp-text{
    text-align: center;
    font-size: 3rem;
    margin-bottom:200px;
  }
</style>