import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Company from '../views/Company.vue'
import Services from '../views/Services.vue'
import ServicesWeb from '../views/ServicesWeb.vue'
import ServicesDP from '../views/ServicesDP.vue'
import News from '../views/News.vue'
import JoinUs from '../views/JoinUs.vue'
import Contact from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'NOVERX合同会社',
      metaTags: [
        {
          name: 'description',
          content: 'NOVERXでは、名古屋にてHPやECサイトなどのWebサイトの制作を行なっています。他にはない感性を重視したデザインや構成で魅力的なWebサイトを制作いたします。HPやECサイトにシステムを組み込むことも可能です。'
        },
      ],
      breadcrumb: 'home',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About - NOVERX合同会社',
      metaTags: [
        {
          name: 'description',
          content: 'NOVERXでは、名古屋にてHPやECサイトなどのWebサイトの制作を行なっています。他にはない感性を重視したデザインや構成で魅力的なWebサイトを制作いたします。HPやECサイトにシステムを組み込むことも可能です。'
        },
      ],
      breadcrumb: 'about',
    },
  },
  {
    path: '/about/company',
    name: 'Company',
    component: Company,
    meta: {
      title: 'Company - NOVERX合同会社',
      breadcrumb: 'about - company',
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: {
      title: 'Services - NOVERX合同会社',
      metaTags: [
        {
          name: 'description',
          content: 'NOVERXでは、名古屋にてHPやECサイトなどのWebサイトの制作を行なっています。他にはない感性を重視したデザインや構成で魅力的なWebサイトを制作いたします。HPやECサイトにシステムを組み込むことも可能です。'
        },
      ],
      breadcrumb: 'services',
    },
  },
  {
    path: '/services/web',
    name: 'ServicesWeb',
    component: ServicesWeb,
    meta: {
      title: 'Web Creation - NOVERX合同会社',
      metaTags: [
        {
          name: 'description',
          content: 'NOVERXでは、名古屋にてHPやECサイトなどのWebサイトの制作を行なっています。他にはない感性を重視したデザインや構成で魅力的なWebサイトを制作いたします。HPやECサイトにシステムを組み込むことも可能です。'
        },
      ],
      breadcrumb: 'services - web creation',
    },
  },
  {
    path: '/services/dp',
    name: 'ServicesDP',
    component: ServicesDP,
    meta: {
      title: 'Design Partner - NOVERX合同会社',
      breadcrumb: 'services - design partner',
    },
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      title: 'News - NOVERX合同会社',
      breadcrumb: 'news',
    },
  },
  {
    path: '/joinus',
    name: 'JoinUs',
    component: JoinUs,
    meta: {
      title: 'Join us - NOVERX合同会社',
      breadcrumb: 'join us',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'Contact - NOVERX合同会社',
      breadcrumb: 'contact',
    },
  },
  {
    path: '/oyasai',
    name: 'Oyasai',
    redirect: () => {
      window.location = "https://www.e-oyasai.com/"
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      return { top: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

trackRouter(router);

export default router
