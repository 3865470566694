<template>
    <div class="strength-part">
        <MiniTitle title="OUR STRENGTH" subTitle="私たちの強み" />
        <div class="strength-part_text">
            <p>感性を起点としたデザイン力とそれを実現させる技術力にあります。</p>
            <p>私たちは、人の感性を重視し、テクノロジーと融合することで本当に価値を感じていただけるプロダクトを提供しています。</p>
        </div>
    </div>
    <div class="strength-part_card-box">
        <div class="strength-card_cover">
            <div class="strength-card_mini-title">
                - Technology -
            </div>
            <div class="strength-card">
                <div class="strength-card_title">
                    技術力
                </div>
                <div class="strength-card_image">
                    <img src="../../assets/strength_tech.png" />
                </div>
                <div class="strength-card_text">
                    <p>- API 連携</p>
                    <p>- システム導入</p>
                    <p>- 決済機能の実装</p>
                    <p>- 高度なデザインの実装</p>
                </div>
            </div>
        </div>
        <div class="strength-card_cover">
            <div class="strength-card_mini-title">
                - Creative -
            </div>
            <div class="strength-card">
                <div class="strength-card_title">
                    デザイン力
                </div>
                <div class="strength-card_image">
                    <img src="../../assets/strength_creative.png" />
                </div>
                <div class="strength-card_text">
                    <p>- 会社の魅力を表現</p>
                    <p>- デザインの視認性</p>
                    <p>- デザインで問題解決</p>
                    <p>- UX/UI・操作性</p>
                </div>
            </div>
        </div>
        <div class="strength-card_cover">
            <div class="strength-card_mini-title">
                - Youth -
            </div>
            <div class="strength-card">
                <div class="strength-card_title">
                    若い感性
                </div>
                <div class="strength-card_image">
                    <img src="../../assets/strength_youth.png" />
                </div>
                <div class="strength-card_text">
                    <p>- 斬新な発想力</p>
                    <p>- 柔軟な対応力</p>
                    <p>- スピード感</p>
                    <p>- 熱量の高さ</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MiniTitle from '../ui/miniTitle.vue';
export default {
    name: "StrengthPart",
    components: { MiniTitle }
};
</script>
<style scoped>
.strength-part_text {
    text-align: center;
}

.strength-part_card-box{
    display: flex;
    justify-content: center;
    margin:5rem auto;
    width:90%;
    height: 700px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../assets/strength_back.png");
}
.strength-card{
    width:250px;
    margin-right:1rem;
    background-color: white;
    border:1px solid #707070;
    padding: 2rem;

}
.strength-card_title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom:2rem;
}
.strength-card_mini-title{
    width: 250px;
    text-align:center;
    font-size:0.9rem;
    color:#4B009F;
    margin-bottom: 1rem;
}

.strength-card_text{
    margin-top:3rem;
}

.strength-card img {
    width:100%;
}

@media (max-width: 960px) {

    .strength-part_text {
        margin: 0 15%;
        text-align: left;
    }

    .strength-part_card-box {
        display: block;
        height: auto;
    }

    .strength-card {
        margin: 0 auto;
    }

    .strength-card_cover {
        margin-bottom: 2rem;
    }

    .strength-card img {
        width: 100%;
        
        text-align: center;
    }
    .strength-card_image{
        width:80%;
        margin: 0 auto;
    }
    .strength-card_text{
        margin-top: 1rem;
    }
    .strength-card_text p{
        margin-bottom: 0.2rem;
    }
}
</style>