<template>
  <Header />
  <router-view />
  <Footer />
</template>
<script>
// @ is an alias to /src
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: "Yu Gothic Medium", "游ゴシック Medium", "YuGothic",
    "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.main-btn {
  font-size: 1.2rem;
  background-color: transparent;
  text-align: center;
  border: 2px solid;
  cursor: pointer;
}
.btn-arrow {
  font-size: 0.9rem;
  padding-left: 0.5rem;
}
.res-br{
  display: none;
}
@media (max-width: 960px) {
  #app {
    font-size: 12px;
  }
  .main-btn {
    font-size: 1rem;
  }
  .res-br {
    display: block;
  }
}
</style>
