<template>
    <div class="joinus">
        <Hero />
        <Content />
    </div>
    <FooterContact />
</template>
<script>
import Hero from "../components/JoinUs/Hero";
import Content from "../components/JoinUs/Content.vue";
import FooterContact from "../components/FooterContact";

export default {
    name: "JoinUs",
    components: {
        Hero,
        Content,
        FooterContact
    },
};
</script>

<style>
</style>