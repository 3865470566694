<template>
  <div class="web-part">
    <div class="web-part_cover">
      <div class="web-part_text_cover">
        <h2>WEB CREATION</h2>
        <p>- Webサイト制作 -</p>
        <button class="main-btn web-part_btn" v-on:click="toWeb">
          learn more<span class="btn-arrow"> ▶︎</span>
        </button>
      </div>
      <div class="web-part_img_cover">
        <img src="@/assets/home_pc.webp" />
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "HomeWeb",
  setup() {
    const router = useRouter();
    const toWeb = () => {
      router.push("/services/web");
    };
    return {
      toWeb,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.web-part {
  background-color: #f5f5f5;
  height: 720px;
}
.web-part_btn {
  padding: 1rem 2rem;
  width: 40%;
}
.web-part_cover {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
}
.web-part_text_cover {
  margin-top: 450px;
}
.web-part_text_cover h2 {
  font-size: 4rem;
  font-weight: bold;
  margin: 0;
}
.web-part_text_cover p {
  font-size: 1.2em;
  margin: 1rem 0;
}
.web-part_img_cover {
  margin-top: 280px;
}
.web-part_img_cover img {
  width: 650px;
}
.web-part_btn:hover {
  color: white;
  background-color: black;
}
@media (max-width: 960px) {
  .web-part {
      height: 100%;
      padding-bottom: 3rem;
    }
  .web-part_cover {
    flex-direction:column-reverse;
    padding:0 50px;
  }
  .web-part_text_cover {
    margin-top: 50px;
  }
  .web-part_text_cover h2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }

  .web-part_text_cover p {
    font-size: 1em;
    margin: 0.5rem 0;
  }
  .web-part_img_cover {
    margin-top: 100px;
  }
  .web-part_img_cover img {
    width: 100%;
  }
  .web-part_btn {
    padding: 1rem;
    width: 150px;
    margin-top:1rem;
  }
}
</style>
