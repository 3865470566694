<template>
    <div class="joinus-content">
        <div class="joinus-block">
            <div class="joinus-block_left">
                <h2>
                    RECRUITMENT<br>
                    INFORMATION
                </h2>
            </div>
            <div class="joinus-block_line"></div>
            <div class="joinus-block_right">
                <p>NOVERXは、<span style="font-weight:bold;">「常にdeveloperであり続ける」</span>をバリューとし、日々体現します。</p>
                <br />
                <p>01. 感性を開拓し続ける。</p>
                <p>02. 学習し続ける。</p>
                <p>03. 常に最良の選択肢を考える。</p>
                <p>04. 目の前のことに真摯に向き合う。</p>
                <br />
                <p>私たちは以上を発揮することで、個々の感性を大切にし新たな感性を開拓し、成長し続けます。</p>
            </div>
        </div>
        <FlowPart />
        <div class="joinus-block">
            <div class="joinus-block_left">
                <img src="../../assets/joinus_image.svg" />
            </div>
            <div class="joinus-block_right">
                <p>NOVERXは、常に事業と組織を拡大しています。</p>
                <p>募集要項は特に設けておりませんが、お仕事としてクリエイションを行う姿勢のある方にジョインしていただきたいと考えています。</p>
                <p>私たちが目指すビジョンに共感いただいた方、ご興味をお持ちの方は、お気軽にご連絡ください。</p>
            </div>
        </div>
    </div>
</template>
<script>
import FlowPart from './FlowPart.vue';
export default {
    name: "Content",
    components: {
        FlowPart,
    }
};
</script>
<style scoped>
.joinus-block{
    display: flex;
    justify-content: space-around;
    margin:0 10%;
}
.joinus-block_line{
    margin: 0 auto;
    width: 2px;
    height: 300px;
    background-color: black;
}
.joinus-block_left {
    width: 40%;
    text-align: center;
}
.joinus-block_left h2 {
    font-size:3.5rem;
    margin:0;
    color:#04005D;
    font-weight: bold;
}
.joinus-block_left img {
    width:90%;
}
.joinus-block_right {
    width: 40%;
    color:#04005D;
    font-size: 1.1rem;
    line-height: 2;
}
@media (max-width: 960px) {
    .joinus-block_left {
        width: 100%;
        text-align: left;
        margin-bottom:3rem;
    }

    .joinus-block_left h2 {
        font-size: 2rem;
        line-height: 1;
    }
    .joinus-block_right {
        width: 100%;
        font-size: 1rem;
        line-height: 2;
    }
    .joinus-block {
        display: block;
    }
    .joinus-block_line {
        display: none;
    }
    .joinus-block_left img {
        width: 100%;
        height:100px;
        object-fit: cover;
    }
}
</style>