<template>
    <aboutTitle title="VISION" />
    <div class="vision">
        <h2>デジタル領域から最高の体験を創り出す。</h2>

    </div>
    <div class="vision-text">
        <div class="vision-text_content">
            <p> NOVERXの強みは、若さゆえの斬新なアイデアを生み出す力とデジタル領域での豊富な経験です。</p>
            <p>感性とテクノロジーを融合させるプロダクトは今までのデザインでは生み出すことができません。プロダクトの目的に忠実でありつつ、他にはない斬新なアイデアを織り交ぜることで初めて創出することができます。</p>
            <p>そういったプロダクト作りを繰り返すことで生まれたノウハウを活かし、人が「最高の体験」と思えるものを創出し続けることが私たちNOVERXのビジョンです。</p>
        </div>
    </div>
</template>
<script>
import aboutTitle from '../ui/aboutTitle.vue';
export default {
    name: "VisionPart",
    components: {
        aboutTitle
    }
};
</script>
<style scoped>
.vision {
    margin-bottom: 5rem;
}

.vision h2 {
    color: #4B009F;
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
}
.vision-text{
    margin-bottom:100px;
}

.vision-text_content {
    margin: 0 22% 5rem;
    font-weight: bold;
    font-size: 1.1rem;
}

@media (max-width: 960px) {
    .vision {
        padding: 0 2rem;
    }

    .vision h2 {
        font-size: 1rem;
        line-height: 2.5;
    }

    .vision-text_minih h3 {
        font-size: 1.1rem;
    }

    .vision-text_content {
        margin: 0 9% 5rem;
        font-size: 0.9rem;
    }

    .vision-text_content p {
        padding-bottom: 1rem;
    }

    .vision-mini-image img {
        width: 75%;
    }
}

</style>