<template>
  <div class="home">
    <Hero />
    <Philosophy />
    <div class="btn-section">
      <button v-on:click="about" class="main-btn about-btn">
        View - About
      </button>
    </div>
    <HomeWeb />
    <HomeDP />
    <FooterContact />
  </div>
</template>

<script>
import Hero from "../components/Home/Hero";
import Philosophy from "../components/Home/Philosophy";
import HomeWeb from "../components/Home/HomeWeb";
import HomeDP from "../components/Home/HomeDP";
import FooterContact from "../components/FooterContact";

export default {
  name: "Home",
  components: {
    Hero,
    Philosophy,
    HomeWeb,
    HomeDP,
    FooterContact,
  },
  methods: {
    about() {
      this.$router.push("/about");
    },
  },
};
</script>

<style>
.about-btn {
  color: #4b009f;
  width: 15%;
  padding: 1.5rem 3rem;
}
.about-btn:hover {
  color: white !important;
  background-color: #4b009f;
}
.btn-section {
  text-align: center;
  margin-bottom: 100px;
}
@media (max-width: 960px) {
  .about-btn {
      width: 200px !important;
      padding: 1rem!important;
  }
}
</style>