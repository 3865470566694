<template>
  <div id="services-content">
    <div class="content-wrapper">
      <contentTitle title="NOVERX POLICY" subTitle="NOVERXの理念" />
      <div class="policy-part">
        <div class="policy-img">
          <img src="../../assets/policy_image.svg" />
        </div>
        <div class="policy-text">
          NOVERX の強みは、感性を起点としたデザイン力とそれを実現させる技術力にあります。
          私たちは、人の感性を重視し、テクノロジーと融合することで本当に価値を感じていただけるプロダクトを提供しています。
          <br><br>
          私たちは、あらゆるビジネス課題に対し、WEBサイトの力を駆使して解決する「 WEBサイト制作事業 」
          <br>デザインとテクノロジーを掛け合わせ、長期に渡ってクライアントの課題に対してコミットする「 デザインパートナー事業 」
          <br>有機野菜の売買をテクノロジーを用いて、Re.デザインした「有機野菜プラットフォーム - OYASAI - 」
          を展開しています。
        </div>
      </div>
    </div>
    <div class="services-part">
      <div class="content-wrapper">
        <contentTitle title="SERVICES" subTitle="サービス一覧" />
      </div>
      <ServiceCard />
      </div>
    </div>
</template>

<script>
import contentTitle from '../ui/contentTitle.vue';
import ServiceCard from './ServiceCard.vue';
export default {
  name: "servicesContent",
  components: {
    contentTitle,ServiceCard
  }
};
</script>

<style scoped>
#services-content {
  min-height: 700px;
  margin-bottom: 3rem;
}
.content-wrapper {
  margin: 0 10%;
}
.policy-part{
  margin-bottom:10rem;
}
.policy-img{
  text-align:center;
  margin:5rem 0 8rem; 
}
.policy-img img {
  width: 80%;
}
.policy-text {
  width:70%;
  font-size: 1rem;
  margin:0 auto;
  font-weight: bold;
  line-height:2;
}
.services-part{
  padding:5rem 0;
  background-color: #F0F0F0;
}
@media (max-width: 960px) {
  .policy-img {
    text-align: center;
    margin: 5rem 0 2rem;
  }
  .policy-img img {
    width: 100%;
  }
  .policy-text {
    width: 90%;
    font-size:0.9rem;
  }
}
</style>