<template>
  <div class="contact">
    <Hero />
    <Form />
  </div>
</template>

<script>
import Hero from "../components/Contact/Hero";
import Form from "../components/Contact/Form";

export default {
  name: "Contact",
  components: {
    Hero,
    Form,
  },
  methods: {
    about() {
      this.$router.push("/about");
    },
  },
};
</script>

<style>
.about-btn {
  color: #4b009f;
  width: 15%;
  padding: 1.5rem 3rem;
}
.about-btn:hover {
  color: white !important;
  background-color: #4b009f;
}
.btn-section {
  text-align: center;
  margin-bottom: 100px;
}
</style>