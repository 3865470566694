<template>
  <div class="hero">
    <div class="hero-text">
      <div class="hero-text_word">
        <p>Fusion</p>
      </div>
      <div class="hero-text_word">
        <p>of</p>
      </div>
      <div class="hero-text_word">
        <p>sensibility</p>
      </div>
      <div class="hero-text_word">
        <p>and</p>
      </div>
      <div class="hero-text_word">
        <p>technology</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
  width: 100%;
  height: 730px;
  background-image: url("../../assets/top.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.hero img {
  width: 100%;
}

.hero-text {
  padding-top: 20%;
}
.hero-text_word {
  margin-left: 20px;
  width: -moz-fit-content; /* Firefox */
  width: fit-content;
}
.hero-text_word p {
  font-size: 4.5rem;
  font-weight: bold;
  background-color: white;
  padding: 0;
  margin: 2.5rem 0 0;
  line-height: 0.6em;
}
@media (max-width: 960px) {
  .hero {
    width: 100%;
    height: 830px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .hero-text_word p {
    font-size: 3.5rem;
  }
  .hero-text {
    padding-top: 45%;
  }
}
</style>
