<template>
    <div class="hero">
        <div class="hero_cover">
            <div class="hero-text">
                <h1>JOIN US</h1>
            </div>
            <div class="hero-img">
                <img src="../../assets/joinus_hero.svg" />
            </div>
            <scrollDown />
        </div>
    </div>
</template>

<script>
import scrollDown from "../ui/scrollDown.vue";
export default {
    name: "Hero",
    components: {
        scrollDown,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
    width: 100%;
    height: 500px;
    margin-bottom: 5rem;
    color: #848484;
}

.hero_cover {
    position: relative;
    height: 100%;
}

.hero-img {
    margin: 8% auto 0;
    width: 40%;
}

.hero-text {
    position: absolute;
    top: -10%;
    left: 30.5%;
    z-index: 100;
}

.hero-text h1 {
    font-size: 3.5rem;
    color: white;
    letter-spacing: 0.3rem;
    font-weight: bold;
}

.scroll-down {
    position: absolute;
    top: 60%;
    left: 42.3%;
}

.contact-text {
    margin-top: 10rem;
    font-size: 1.2rem;
    text-align: center;
    color: #000000;
    font-weight: bold;
}
@media (max-width: 960px) {
    .hero-img {
        width: 80%;
    }

    .hero-text h1 {
        font-size: 3.5rem;

    }

    .hero-text {
        left: 11.5%;
    }

    .scroll-down {
        top: 70%;
        left: 35.5%;
    }
}

@media (max-width: 520px) {
    .hero {
        height: 300px;
    }

    .scroll-down {
        top: 60%;
        left: 21%;
    }

    .hero-text {
        top: -16%;
        left: 11.5%;
    }

    .hero-text h1 {
        font-size: 2.3rem;

    }
}
</style>
