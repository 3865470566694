<template>
    <div class="info">
        <h2>会社情報</h2>
    </div>
    <div class="info-content">
        <div class="info-block" v-for="(i,key) in infos" :key="key">
            <div class="info-header">
                {{i.header}}
            </div>
            <div class="info-text">
                {{i.text}}
            </div>

        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
export default {
    name: "Info",
    setup() {
        const infos = ref([
            { header: "会社名", text: "NOVERX(ノヴァークス)合同会社" },
            { header: "英語表記", text: "NOVERX Inc." },
            { header: "代表取締役", text: "鰻渕 勇斗" },
            { header: "設立", text: "2020年9月" },
            { header: "所在地", text: "愛知県名古屋市中区大須一丁目7番14号パークIMビル2F" },
            { header: "事業内容", text: "Web制作・運営・販売、アプリ制作、産直通販サイトOYASAIの開発、運営" },
        ])
        return {
            infos
        }
    }
};
</script>
<style scoped>
.info {
    margin-bottom: 5rem;
}

.info h2 {
    color: #04005D;
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
}

.info-content{
    margin:0 20% 5rem;
}

.info-block{
    margin-bottom: 2rem;
    border-bottom:1px solid black;
}

.info-text{
    margin-left:1rem;
    font-size: 1.1rem;
}
@media (max-width: 960px) {
    .info-content {
        margin: 0 10% 5rem;
    }
}

</style>