<template>
    <div class="link-button_cover">
        <div class="link-button" v-on:click="toLink">
            <h3>{{ title }}</h3>
        </div>
        <p>view<span class="btn-arrow"> ▶︎</span></p>
    </div>
</template>
<script>
import { useRouter } from "vue-router";
export default {
    name: "miniTitle",
    props: {
        title: String,
        link: String,
    },
    setup(props) {
        const router = useRouter();
        const toLink = () => {
            router.push(props.link);
        };
        return {
            toLink,
        };
    },
};
</script>

<style scoped>

.link-button_cover {
    color: #4B009F;
    width: 100%;
    text-align: center;
}
.link-button{
    border: 1px solid #4B009F;
    border-radius: 50%;
    width:200px;
    height:200px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto 1rem;
    cursor: pointer;
}
.link-button:hover {
    border: 1px solid #4B009F;
    background-color: #4B009F;
    color:white;
    transform: scale(1.1);
    transition: .6s cubic-bezier(0.33, 1, 0.68, 1);
}
.link-button h3{
    font-size: 2rem;
    font-weight: bold;
}

@media (max-width: 960px) {
    
}
</style>