<template>
  <div class="web-creation">
    <Hero />
    <WebContent/>
  </div>
  <FooterContact />
</template>
<script>
import Hero from "../components/Web/Hero";
import FooterContact from "../components/FooterContact";
import WebContent from "../components/Web/WebContent.vue";

export default {
  name: "WebProduction",
  components: {
    Hero,
    FooterContact,
    WebContent,
},
  methods: {
    about() {
      this.$router.push("/about");
    },
  },
};
</script>

<style>
</style>