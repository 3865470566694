<template>
  <div class="dp-part">
    <div class="dp-part_cover">
      <div class="dp-part_text_cover">
        <div class="dp-part_text_word">
          <h2>DESIGN PARTNER</h2>
        </div>
        <div class="dp-part_text_word">
          <p>- デザインパートナー事業 -</p>
        </div>
        <button class="main-btn dp-part_btn" v-on:click="toDP">
          learn more <span class="btn-arrow"> ▶︎</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "HomeDP",
  setup() {
    const router = useRouter();
    const toDP = () => {
      router.push("/services/dp");
    };
    return {
      toDP,
    };
  },
};
</script>

<style scoped>
.dp-part {
  background-image: url("../../assets/dp_back.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 720px;
}
.dp-part_cover {
  display: flex;
  justify-content: space-between;
  padding: 0 5%;
}
.dp-part_text_cover {
  margin-top: 450px;
}
.dp-part_text_cover h2 {
  font-size: 4.5rem;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}
.dp-part_text_cover p {
  font-size: 1.4em;
  margin: 0;
}
.dp-part_img_cover {
  margin-top: 280px;
}
.dp-part_img_cover img {
  width: 650px;
}
.dp-part_btn {
  font-size: 1.3rem;
  padding: 1rem 1.8rem;
  width: 31%;
  color: white;
  background-color: black;
}
.dp-part_btn:hover {
  color: black;
  background-color: white;
  border-color: white;
}
.dp-part_text_word {
  background-color: white;
  margin-bottom: 1rem;
  padding: 0.1rem;
  width: -moz-fit-content; /* Firefox */
  width: fit-content;
}
@media (max-width: 960px) {
  .dp-part {
      height: 100%;
      padding-bottom: 3rem;
    }
  .dp-part_text_cover {
    margin-top: 400px;
  }
  .dp-part_text_cover h2 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    margin: 0;
  }
  .dp-part_btn {
    font-size: 1rem;
    padding: 1rem;
    width: 150px;
    color: white;
    background-color: black;
  }
}

</style>
